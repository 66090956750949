import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import {COLORS} from '../values/colors'
import logo from "./img/logo.png";
import PhonelinkRingIcon from "@mui/icons-material/PhonelinkRing";
import AnchorLink from "react-anchor-link-smooth-scroll";


function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar sx={{backgroundColor: COLORS.navbar}} position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={logo} />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <AnchorLink
                style={{ textDecoration: "none" }}
                offset="150"
                href="#form_callback"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button fontFamily="Roboto" textAlign="center">
                    ЦEНЫ
                  </Button>
                </MenuItem>
              </AnchorLink>
              <AnchorLink
                style={{ textDecoration: "none" }}
                offset="50"
                href="#how_work"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button fontFamily="Roboto" textAlign="center">
                    КАК РАБОТАЕМ
                  </Button>
                </MenuItem>
              </AnchorLink>
              <AnchorLink
                style={{ textDecoration: "none" }}
                offset="50"
                href="#our_works"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button fontFamily="Roboto" textAlign="center">
                    НАШИ РАБОТЫ
                  </Button>
                </MenuItem>
              </AnchorLink>
              <AnchorLink
                style={{ textDecoration: "none" }}
                offset="300"
                href="#contacts"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button fontFamily="Roboto" textAlign="center">
                    Контакты
                  </Button>
                </MenuItem>
              </AnchorLink>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={logo} />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <AnchorLink style={{ textDecoration: "none" }} offset="300" href="#form_callback">
              <Button sx={{ my: 2, color: "white", display: "block" }}>
                Цены
              </Button>
            </AnchorLink>
            <AnchorLink style={{ textDecoration: "none" }} offset="200" href="#how_work">
              <Button sx={{ my: 2, color: "white", display: "block" }}>
                Как работаем
              </Button>
            </AnchorLink>
            <AnchorLink style={{ textDecoration: "none" }} offset="200" href="#our_works">
              <Button sx={{ my: 2, color: "white", display: "block" }}>
                Наши работы
              </Button>
            </AnchorLink>
            <AnchorLink style={{ textDecoration: "none" }} offset="300" href="#contacts">
              <Button sx={{ my: 2, color: "white", display: "block" }}>
                Контакты
              </Button>
            </AnchorLink>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <PhonelinkRingIcon color="whiteq" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleCloseUserMenu}>
              <AnchorLink
                style={{ textDecoration: "none" }}
                offset="150"
                href="#form_callback"
              ><Typography textAlign="center"><Button sx={{ display: "block" }}>Заказать звонок</Button></Typography>
              </AnchorLink>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
