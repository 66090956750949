import {
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import block3 from "./img/block3.png";
import block32 from "./img/block32.png";
import {COLORS} from '../values/colors'

export default function Block3() {
  return (
    <Box
      sx={{
        backgroundColor: COLORS.back,
      }}
    >
      <Container
      fullWidth={true}
        sx={{
          padding: 3,
          textAlign: "center",
          flexGrow: 1,
          display: { xs: "none", md: "flex" },

        }}
      >
        <Stack direction="row" spacing={4}>
          <img src={block3} />
          <Grid
            spacing={4}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid xs>
              <Typography
                variant="h1"
                sx={{ color: "white", fontFamily: "Roboto Bold", fontSize: 30 }}
              >
                <span style={{color: '#e91e63'}}>Пройдите</span> небольшой опрос <span style={{color: '#e91e63'}}>и узнайте</span> сколько стоят наши лестницы.
              </Typography>
            </Grid>
            <Grid xs>
              <Typography
                sx={{
                  color: "white",
                  fontFamily: "Roboto",
                  fontSize: 18,
                  mt: 5,
                }}
              >
                Бонусом за заполненную анкету вы получите каталог наших лестниц
                для подробного изучения наших технологий и дополнительную скидку
                в <span style={{color: '#e91e63'}}>10 000 рублей</span> на лестницу.
              </Typography>
            </Grid>
            <Grid xs>
            <AnchorLink
                style={{ textDecoration: "none" }}
                offset="150"
                href="#form_callback"
              >
              <Button
                variant="contained"
                size="lg"
                fullWidth={true}
                color="green"
                sx={{mt:6}}
              >
                Оставить заявку
              </Button>
              </AnchorLink>
            </Grid>
          </Grid>

          <Box><img src={block32} /></Box>
        </Stack>
      </Container>
      <Container
        sx={{
          padding: 3,
          textAlign: "center",
          flexGrow: 1,
          display: { xs: "flex", md: "none" },
        }}
      >
        <Stack direction="row">
        <Grid
            spacing={4}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid xs>
              <Typography
                variant="h1"
                sx={{ color: "white", fontFamily: "Roboto Bold", fontSize: 30 }}
              >
                <span style={{color: '#e91e63'}}>Пройдите</span> небольшой опрос <span style={{color: '#e91e63'}}>и узнайте</span> сколько стоят наши лестницы.
              </Typography>
            </Grid>
            <Grid xs>
              <Typography
                sx={{
                  color: "white",
                  fontFamily: "Roboto",
                  fontSize: 18,
                  mt: 5,
                }}
              >
                Бонусом за заполненную анкету вы получите каталог наших лестниц
                для подробного изучения наших технологий и дополнительную скидку
                в <span style={{color: '#e91e63'}}>10 000 рублей</span> на лестницу.
              </Typography>
            </Grid>
            <Grid xs>
            <AnchorLink
                style={{ textDecoration: "none" }}
                offset="150"
                href="#form_callback"
              >
              <Button
                variant="contained"
                size="lg"
                fullWidth={true}
                color="green"
                sx={{mt:6}}
              >
                Оставить заявку
              </Button>
              </AnchorLink>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}
