import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import block2 from "./img/block2.png";
import block21 from "./img/block21.png";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import Filter1OutlinedIcon from "@mui/icons-material/Filter1Outlined";
import {COLORS} from '../values/colors'

export default function Block2() {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  return (
    <Box
      sx={{
        backgroundColor: COLORS.back,
        backgroundImage: `url(${block2})`,
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container sx={{ padding: 3, textAlign: "center" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid xs>
            <Box
              sx={{
                border: "2px solid rgba(255, 255, 255, 0.1)",
                color: "white",
                mb: 2,
              }}
            >
              <List dense={dense}>
                <ListItem>
                  <ListItemIcon>
                    <EngineeringOutlinedIcon
                      color="whiteq"
                      sx={{ fontSize: 45 }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Собственная монтажная служба и отдел контроля качества"
                    secondary={secondary ? "Secondary text" : null}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <BuildOutlinedIcon color="whiteq" sx={{ fontSize: 40 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Изготавливаем лестницы на металлическом каркасе на собственном производстве"
                    secondary={secondary ? "Secondary text" : null}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Filter1OutlinedIcon color="whiteq" sx={{ fontSize: 40 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Производим монтаж лестницы на объекте без сварки за 1 день"
                    secondary={secondary ? "Secondary text" : null}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <WorkspacePremiumIcon color="whiteq" sx={{ fontSize: 40 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Гарантия 10 лет !"
                    secondary={secondary ? "Secondary text" : null}
                  />
                </ListItem>
              </List>
            </Box>
          </Grid>
          <Grid xs={6}>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, ml: 10 }}>
              <img src={block21} />
            </Box>
          </Grid>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, width: 500 }}>
              <img src={block21} />
            </Box>
        </Grid>
      </Container>
    </Box>
  );
}
