import { Container } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Form from "./Form";
import {COLORS} from '../values/colors'

export default function Block5() {
  return (
    <Box sx={{ backgroundColor: COLORS.back }}>
      <Container><br/></Container>
      <Form />
    </Box>
  );
}
