import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import {COLORS} from '../values/colors'
import React from "react";

export default function Block10() {
  return (
    <Box sx={{ backgroundColor: COLORS.back, padding: 3 }}>
      <Container
        sx={{
          color: "white",
          backgroundColor: COLORS.navbar,
          position: 'relative',
          top: 90,
          padding: 3,
          boxShadow: "0 0 5px 1px white"
        }}
      >
        <Box sx={{ padding: 2, border: "1px solid rgba(255, 255, 255, 0.1)", }}>
          <Typography sx={{textAlign: "center"}} variant="h5">
            Монтаж каркаса лестницы производится{" "}
            <span style={{ color: "#e91e63" }}> без сварки и резки</span>
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Лестница собирается полностью на болтовых и специальных соединениях.
            никаких искр, дыма и грязи!
          </Typography>
          <ul>
            <li>
              <Typography>Обои целы</Typography>
            </li>
            <li>
              <Typography>Линолеум не прожгли</Typography>
            </li>
            <li>
              <Typography>Занавески не пропахли</Typography>
            </li>
          </ul>
          <Typography variant="h7" component='div'>Нужно ли это, чтобы быть спокойным за свой ремонт?</Typography>
          <Typography  variant="h6">КОНЕЧНО ДА!</Typography>
        </Box>
      </Container>
    </Box>
  );
}
