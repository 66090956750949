import { Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import slider11 from "./img/block7/slider1/slider11.jpg";
import slider12 from "./img/block7/slider1/slider12.jpg";
import slider13 from "./img/block7/slider1/slider13.jpg";
import slider14 from "./img/block7/slider1/slider14.jpg";
import slider15 from "./img/block7/slider1/slider15.jpg";
import slider21 from "./img/block7/slider2/slider21.jpg";
import slider22 from "./img/block7/slider2/slider22.jpg";
import slider23 from "./img/block7/slider2/slider23.jpg";
import slider24 from "./img/block7/slider2/slider24.jpg";
import slider31 from "./img/block7/slider3/slider31.jpg";
import slider32 from "./img/block7/slider3/slider31.jpg";
import slider33 from "./img/block7/slider3/slider31.jpg";
import slider34 from "./img/block7/slider3/slider31.jpg";
import slider41 from "./img/block7/slider4/slider41.jpg";
import slider42 from "./img/block7/slider4/slider41.jpg";
import slider43 from "./img/block7/slider4/slider41.jpg";
import {COLORS} from '../values/colors'

const handleDragStart = (e) => e.preventDefault();
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};
const slider1 = [
  <img src={slider11} onDragStart={handleDragStart} role="presentation" />,
  <img src={slider12} role="presentation" />,
  <img src={slider13} role="presentation" />,
  <img src={slider14} role="presentation" />,
  <img src={slider15} role="presentation" />,
];
const slider2 = [
  <img src={slider21} onDragStart={handleDragStart} role="presentation" />,
  <img src={slider22} role="presentation" />,
  <img src={slider23} role="presentation" />,
  <img src={slider24} role="presentation" />,
];
const slider3 = [
  <img src={slider31} onDragStart={handleDragStart} role="presentation" />,
  <img src={slider32} role="presentation" />,
  <img src={slider33} role="presentation" />,
  <img src={slider34} role="presentation" />,
];
const slider4 = [
    <img src={slider41} onDragStart={handleDragStart} role="presentation" />,
    <img src={slider42} role="presentation" />,
    <img src={slider43} role="presentation" />,
  ];

export default function Block7() {
  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ my: 4 }}
      >
        <Grid xs>
          <Box
            sx={{ width: 360, height: 380, boxShadow: "10px 10px 5px gray" }}
          >
            <AliceCarousel
              mouseTracking
              autoPlay
              autoWidth
              disableButtonsControls
              disableDotsControls
              autoPlayInterval={3000}
              items={slider1}
              responsive={responsive}
            />
          </Box>
        </Grid>
        <Grid xs>
          <Box sx={{}}>
            <h3 style={{ fontSize: 26 }}>Двойной ломаный косоур</h3>
            <Typography>
              Ломаный косоур из профильной трубы. Такой каркас имеет
              неограниченный срок службы. Он прочный и надежный. Собирается без
              сварки. Ступени крепятся без болтов и саморезов, крепеж скрытый.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row-reverse"
        justifyContent="center"
        alignItems="center"
        sx={{ my: 4 }}
      >
        <Grid xs>
          <Box
            sx={{ width: 360, height: 380, boxShadow: "-10px 10px 5px gray" }}
          >
            <AliceCarousel
              mouseTracking
              autoPlay
              autoWidth
              disableButtonsControls
              disableDotsControls
              autoPlayInterval={3000}
              items={slider2}
              responsive={responsive}
            />
          </Box>
        </Grid>
        <Grid xs>
          <Box sx={{}}>
            <h3 style={{ fontSize: 26 }}>Монокосоур</h3>
            <Typography>
              Монокосоур также изготавливается из профильной трубы. Ступени
              крепятся к центральной пластине. Конструкция монокосоура
              разрабатывалась нами исходя из соображений эстетичности, гармонии
              и геометрического совершенства. Лестница на монокосоуре прекрасно
              вписывается в любой современный интерьер.Для дополнительной
              жесткости рекомендуем связывать ступени больцами по краям.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ my: 4 }}
      >
        <Grid xs>
          <Box
            sx={{ width: 360, height: 380, boxShadow: "10px 10px 5px gray" }}
          >
            <AliceCarousel
              mouseTracking
              autoPlay
              autoWidth
              disableButtonsControls
              disableDotsControls
              autoPlayInterval={3000}
              items={slider3}
              responsive={responsive}
            />
          </Box>
        </Grid>
        <Grid xs>
          <Box sx={{}}>
            <h3 style={{ fontSize: 26 }}>Тетива из листа</h3>
            <Typography>
              Изготавливается на лазерной резке из листа 8 мм. Идеальная
              геометрия. Возможен вариант под ограждение на несущем стекле.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row-reverse"
        justifyContent="center"
        alignItems="center"
        sx={{ my: 4 }}
      >
        <Grid xs>
          <Box
            sx={{ width: 360, height: 380, boxShadow: "-10px 10px 5px gray" }}
          >
            <AliceCarousel
              mouseTracking
              autoPlay
              autoWidth
              disableButtonsControls
              disableDotsControls
              autoPlayInterval={3000}
              items={slider4}
              responsive={responsive}
            />
          </Box>
        </Grid>
        <Grid xs>
          <Box sx={{}}>
            <h3 style={{ fontSize: 26 }}>Консольная лестница</h3>
            <Typography>
              Консольная лестница представляет собой конструкцию из ступеней
              закрепленных в стены лестничного проема. По внешней стороне
              несущую нагрузку несет стекло или больца, соединяющие все ступени
              в единую монолитную конструкцию.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
