import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import slider1 from "./img/block4/1.jpg";
import slider2 from "./img/block4/2.jpg";
import slider3 from "./img/block4/3.jpg";
import slider4 from "./img/block4/4.jpg";
import slider5 from "./img/block4/5.png";
import slider6 from "./img/block4/6.jpg";
import {COLORS} from '../values/colors'

const handleDragStart = (e) => e.preventDefault();
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};
const items = [
  <img src={slider1} onDragStart={handleDragStart} role="presentation" />,
  <img src={slider2} onDragStart={handleDragStart} role="presentation" />,
  <img src={slider3} onDragStart={handleDragStart} role="presentation" />,
  <img src={slider4} onDragStart={handleDragStart} role="presentation" />,
  <img src={slider5} onDragStart={handleDragStart} role="presentation" />,
  <img src={slider6} onDragStart={handleDragStart} role="presentation" />,
];
export default function Block4() {
  return (
    <Box
      justifyContent="center"
      textAlign="center"
      sx={{ backgroundColor: COLORS.back }}
    >
      <Typography
        color="white"
        variant="h6"
        sx={{ fontFamily: "Roboto", fontSize: 24, mb: 5 }}
      >
        Закажите металлический каркас лестницы, если ремонт ещё в самом разгаре
      </Typography>
      <AliceCarousel
        mouseTracking
        autoPlay
        disableButtonsControls
        disableDotsControls
        autoPlayInterval={3000}
        items={items}
        responsive={responsive}
      />
    </Box>
  );
}
