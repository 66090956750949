import { Grid, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React from "react";
import logo from "./img/logo.png";
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {COLORS} from '../values/colors'

export default function Footer() {
  return (
    <Box id="contacts" sx={{ backgroundColor: COLORS.back, padding: 2 }}>
      <Container sx={{ color: "white" }}>
        <Grid container direction="row">
          <Grid md={4}>
            <img src={logo} />
            <Typography sx={{ mt: 2 }}>
              © 2014-2023 Все права защищены.
            </Typography>
          </Grid>
          <Grid md={4}>
            <Typography fontSize={18}>Центр лестниц "Наш Формат"</Typography>
            <Typography fontSize={12}>г.Москва и Московская обл.</Typography>
          </Grid>
          <Grid md={4} sx={{textAlign: 'right'}}>
            <Stack direction="row" spacing={3}>
                <AlternateEmailOutlinedIcon color="whiteq"/>
                &nbsp;support@nashformat.online
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
